// Grid system
.main {
  @include make-col-ready();

  @include media-breakpoint-up(sm) {
    @include make-col(12);
  }
  .sidebar-primary & {

  @include media-breakpoint-up(sm) {
    @include make-col(8);
  }
  }
}
.sidebar {
  @include make-col-ready();


  @include media-breakpoint-up(sm) {
    @include make-col(4);
  }
}