.nav {
	> li {
		> a {
		}
	}
}
.navbar-header {
	width: 100%;
}

.navbar-default {
	.navbar-nav {
		a {
			display: block;
			color: #fff;
			padding: 10px 12px;
		}

		.active a {
			background-color: #470000;

			&:focus,
			&:hover {
				background-color: #470000;
			}
		}
	}
}