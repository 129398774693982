@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,300italic,400italic,700italic');

body {
	background: #000 url('../images/bodybg.jpg') no-repeat center top;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
}

.logo {
  margin: 0 auto;
  @include media-breakpoint-up(sm) {
    margin: 0;
  }
}

.banner {
  padding-top: 20px;
  margin-bottom: 20px;

  .col-sm-8 {
    text-align: center;
    @include media-breakpoint-up(sm) {
	   text-align: left;
    }
  }
}

.langsel {
	// margin-top: 5px;
  @include media-breakpoint-up(sm) {
    // float: left;
    // margin-top: 0px;
    margin-left: 0px;
  }
  @include media-breakpoint-up(md) {
    // margin-top: 94px;
    margin-left: 10px;
  }
  img {
  	margin-left: 10px;
  }
}

.navbar-collapse {
	// needs latest Compass, add '@import "compass"' to your scss
	background-color: #a72324; // Old browsers
	background: rgb(167,35,36); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2E3MjMyNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM2MzE1MTYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  rgba(167,35,36,1) 0%, rgba(99,21,22,1) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(167,35,36,1)), color-stop(100%,rgba(99,21,22,1))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(167,35,36,1) 0%,rgba(99,21,22,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(167,35,36,1) 0%,rgba(99,21,22,1) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(167,35,36,1) 0%,rgba(99,21,22,1) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(167,35,36,1) 0%,rgba(99,21,22,1) 100%); /* W3C */

	-webkit-box-shadow: 0px 6px 8px -2px rgba(0,0,0,0.41);
	-moz-box-shadow: 0px 6px 8px -2px rgba(0,0,0,0.41);
	box-shadow: 0px 6px 8px -2px rgba(0,0,0,0.41);

}

h1 {
	margin-top:0;
	margin-bottom: 20px;
	font-size: 1.278em;
	font-weight: 700;
}
p {
	margin-bottom: 20px;
}
a {
	color:#A1A1A1;
	// text-decoration: underline;
	&:hover {
		color: #fff;
	}
}

.navbar-toggler {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  background-image: none;
  border: 1px solid #77090a;
  border-radius: 4px;
  margin-right: 0;
  width: 100%;
  background-color: #a72324;
  margin-bottom: 0px;

  &:hover,
  &:focus {
    background-color: #91090a;
  }
  .icon-bar {
    background-color: #fff;
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;

    +.icon-bar {
      margin-top: 4px;
    }
  }
}

.navlines {
  float:left;
  padding-top: 4px;
}

.navbar-text {
  float:right;
  margin-top:0;
  color: #fff;
  margin-bottom:0;
  padding-top: 0;
  padding-bottom: 0;
}

.headsocial {
  text-align: center;
  @include media-breakpoint-up(sm) {
		text-align:left;
  }
}
.socialicons {
	list-style-type: none;
	padding:0;
	margin:10px auto 0 auto;
	display: inline-block;
  @include media-breakpoint-up(sm) {

    margin: 0;
		display: block;
  }

	li {
		display: inline-block;
		padding:0 3px;
		margin: 0;
	}
	img {
		width:30px;
		height: auto;
	  @include media-breakpoint-up(sm) {
			width:43px;
	  }
	}
}

#fbwrap {
  width: 100%;
  height: 600px;
}

.nosto {
	// float:left;
	position: relative;
  // max-width: 263px;
  width: 100%;
  height: auto;
  font-size:1em;
  margin: 0 auto 20px auto;
  
	a {
		color: darken(#fff, 25%);
		&:hover {
			color:#fff;
		}
	}
	img {
		border:5px solid #51220a;
	}
	span {
		margin: 0;
		padding: 10px 10px;
		display: block;
		position: absolute;
		bottom:20px;
    left: 0;
    right: 0;

    border-left:5px solid #51220a;
    border-right: 5px solid #51220a;
		text-align:center;
		background-color:rgba(0, 0, 0, 0.7);
	}
}

.img-responsive {
  @extend .img-fluid;
}

// GALLERY

.page-template-template-galleria {
  .wrap {
    .content {
      // background-color: #000;
      padding: 0;
      h1{
        color: #fff;
        font-size:13px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 7px 1% 0 1%;
      }
      .gallery {
		    background-color:rgba(0, 0, 0, 0.7);
        @include media-breakpoint-up(md) {
         // margin-left: 1%;
        }
          padding-bottom: 17px;
        .grid-sizer {
          width:50%;
          @include media-breakpoint-up(sm) {
            width:25%;
          }
          @include media-breakpoint-up(md) {
            width:20%;
          }
        }
        .gallerythumb {
          text-align: left;
          float:left;
          width:50%;
          padding: 1%;
          @include media-breakpoint-up(sm) {
            width:25%;
          }
          @include media-breakpoint-up(md) {
            width:20%;
          }
          .img-responsive {
            display: inline;
          }
          a {
          	margin: 0 5px 5px 0;
          	float: left;
          	clear: left;
          	// width:100%;
          }
          .phototitle {
          	font-size: 0.7em;
          	float: left;
          	line-height: 1.2;
 			      padding-top: 4px;
 			      width:100%;
          }
        }
      }
      .gallerywrap {
        float:left;
        width: 100%;
        clear: both;
      }
    }
  }
}
.attachment {
  .wrap {
    .content {
      // padding: 0;
      a {
        color:#fff;
        font-weight: 700;
        span {
          color:#ff7500 !important;
        }
      }
			.main {
				.attachment-wrap {
				background-color:rgba(0, 0, 0, 0.7);
					.row {
						padding-top: 5px;
					}
				}
			}
      .gallerysidebar {
        // margin-left: 5px;
        padding: 10px 0;
        // padding-left: 17px;
        .grid-sizer {
          width:33.2%;
          @include media-breakpoint-up(sm) {
            width:24.9%;
          }
          @include media-breakpoint-up(md) {
            width:49.9%;
          }
        }
        .gallerysidebarthumb {
          float:left;
          padding: 4px 2px 0 2px;
          width:33.2%;
          @include media-breakpoint-up(sm) {
            width:24.9%;
          }
          @include media-breakpoint-up(md) {
            width:49.9%;
          }
          &:hover {
          }
        }
      }
      .returnback {
        margin:10px 0;
        text-transform: uppercase;
      }
      .img-responsive {
        display: block;
        max-width: 100%;
        max-height: 700px;
        height: auto;
        width: auto;
        margin: 0 auto;
      }
      .attachmentwrap {
        color:#fff;
        text-align: center;
        h1{
          font-size:15px;
          font-weight: 700;
          // text-transform: uppercase;
          margin-top: 10px;
          margin-bottom: 5px;
        }
        h3 {
          font-size:13px;
          margin-bottom: 5px;
          font-weight: 700;
          // text-transform: uppercase;
        }
        p {
          font-size:13px;
        }
        a {
          font-weight: 500;
        }
      }
    }
  }
}
.form-group {
  label {
    padding-bottom: 5px;
    font-weight: 300;
  }
}
// attachment social
#socialbox {
  a {
    color:#ff7500;
    font-weight: 700;
    // line-height: 34px;
    font-size:13px;
    span {
      display:none;
      color:#fff !important;
      @include media-breakpoint-up(sm) {
        display:inline;
      }

    }
  }
  p {
    text-align:center;
  }
  // float: left;
  &.videobox {
    margin-top:-10px;
    margin-left:15px;
  }
  &.attachment {
    width: 100%;
    overflow: hidden;
    line-height: 15px;
    margin: 10px;
    ul {
      list-style: none;
      position: relative;
      float: left;
      display: block;
      left: 50%;
      padding:0px !important;
      margin-top:0px;
      margin-bottom:0px;
      margin-left:0px;
      li {
        position: relative;
        float: left;
        display: block;
        right: 50%;
        &.pinit {
          margin-right:50px;
          // margin-top: 6px;
        }
        &.fb {
          // margin-top: 6px;
          float:left;
          margin-right: 20px;
          @media (max-width: 415px) {
            // display:none;
          }
        }
        &.gplus {
          // margin-top: 6px;
          float:left;
          margin-right: 0px;
          @media (max-width: 475px) {
            display:none;
          }
        }
        &.twit {
          // margin-top: 6px;
          float:left;
          margin-right: 10px;
          @media (max-width: 475px) {
            display:none;
          	margin-right: 0px;
          }
        }
        &.previous-link {
          margin-right:10px;
          @include media-breakpoint-up(sm) {
            margin-right:25px;
          }
        }
        &.next-link {
          margin-right:10px;
          @include media-breakpoint-up(sm) {
           margin-left:25px;
          }
        }
      }
    }
  }
  .fb_edge_comment_widget { display: none !important; }
}

#wpadminbar #wp-admin-bar-edit-gallery .ab-icon:before {
    content: "\f464";
    top: 2px;
}

.entry-content-asset {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.linkrow {
	background-color:rgba(0, 0, 0, 0.7);
	padding: 10px 0;
	text-align: center;
  	@include media-breakpoint-up(sm) {
		text-align: left;
    }
	.linklogo {
		text-align: center;
		img {
			margin:0 auto;
		}
	}
}


#map-canvas {
	position: relative;
	padding-bottom: 56.25%;
	// padding-bottom: 35%;
	padding-top: 25px;
	height: 0 !important;
	margin-bottom: 20px;
 	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 180px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 1em;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.78);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.dropdown-menu>li>a {
  display: block;
  padding: 3px 15px;
  font-weight: 300;
  color: #FFF;
  white-space: nowrap;
}

.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
  text-decoration: none;
  color: #FFFFFF;
  background-color: #000000;
}

.dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #676767;
}

p.useful-link {
  margin-bottom: 0px;
}

.content-info {

  .TA_certificateOfExcellence {

    display: inline-block !important;
  }

}
