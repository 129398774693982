.content-info {
	margin-top: 40px;
	background-color:rgba(0, 0, 0, 0.5);
	border-top: 1px solid #333333;
	text-align: center;
	p {
		color:#969696;
		font-size:0.7em;
		padding:20px 20px 3px;
	}
	a {
		color:#969696;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}